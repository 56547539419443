import { IconButton, Toolbar, Typography, AppBar } from "@mui/material";
import { useEffect, useState } from "react";
import { extractStyles } from "../Pages/core/_utils";

const AppBarComponent = ({ roomName, steps, activeStep, second_widget_color,
  main_widget_color, css_widget }: any) => {
  const [hfContainerStyles, setHfContainerStyles] = useState({});

  useEffect(() => {
    if (css_widget && typeof css_widget === 'string') {
      const extractedStyles = extractStyles(css_widget, '.hf-container');
      setHfContainerStyles(extractedStyles);
    } else {
      console.error('css_widget is undefined or not a string');
    }
  }, [css_widget]);
  return (
    <AppBar position="static" className="header-container-custom" sx={{
      backgroundColor: main_widget_color ? `${main_widget_color} !important` : 'var(--primary-color)', ...hfContainerStyles,
    }}>
      <Toolbar>

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {activeStep == 4 ? "Checkout" : steps[activeStep]}
        </Typography>
        <Typography variant="h6" component="div">
          {roomName}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
export default AppBarComponent;
