import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    if (!config.headers) return config;

    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response && error.response.status === 401) {
      // window.location.href = '/login'
    }

    return Promise.reject(error);
  },
);

export default axiosClient;
