import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { extractStyles, formatDateString } from "../Pages/core/_utils";
import { ClearIcon } from "@mui/x-date-pickers";
import TotalCard from "../components/total-Card";

const InvoiceStep = ({
  selectedRange,
  handleChangeDiscount,
  deleteSelectedRange,
  handleAddComment,
  resetSelectedRange,
  css_widget
}) => {

  const [stylesOne, setStylesOne] = useState({});
  const [stylesTwo, setStylesTwo] = useState({});
  const [stylesThree, setStylesThree] = useState({});
  
  useEffect(() => {
    if (css_widget && typeof css_widget === 'string') {
      const extractedStyles = extractStyles(css_widget, '.card-wrap');
      const extractedStylesTwo = extractStyles(css_widget, '.summary-date-card');
      const extractedStylesThree = extractStyles(css_widget, '.addons-total-card');
      setStylesOne(extractedStyles);
      setStylesTwo(extractedStylesTwo)
      setStylesThree(extractedStylesThree)
    } else {
      console.error('css_widget is undefined or not a string');
    }
  }, [css_widget]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ backgroundColor: "white", padding: 2 ,...stylesOne,...stylesTwo}}>
          {selectedRange.reservation.map((element, index) => (
            <Box key={index} p={2}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography variant="h6" gutterBottom>
                      {formatDateString(element.day)}
                    </Typography>
                    {element.range.map((el, idx) => (
                      <Box
                        key={idx}
                        p={1}
                        mb={2}
                        width="100%"
                        display="flex"
                        flexDirection="column"
                      >
                        <Grid container

                        >
                          <Grid item xs={4} md={4} textAlign={"left"} ><Typography
                            sx={{ mb: 0, fontWeight: 400 }}
                            color="black"
                          >
                            {`${el.start} - ${el.end} (${el.totalHours} hrs)`}
                          </Typography></Grid>
                          <Grid item xs={4} md={4} >  {el.selectedAddons.length > 0 && (
                            <Box>
                              {el.selectedAddons.map((add, iddx) => (
                                <Typography
                                  textAlign={"start"}
                                  key={iddx}
                                  sx={{ mb: 0, fontWeight: 400 }}
                                  color="black"
                                >
                                  {`${add.quantity} x ${add.addonName}`}
                                </Typography>
                              ))}
                            </Box>
                          )}</Grid>
                          <Grid item xs={4} md={4} ><Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            width="auto"
                          >
                            <Typography sx={{ mr: 2 }}>
                              ${el.totalAmount}
                            </Typography>
                            <IconButton
                              onClick={(e) => {
                                deleteSelectedRange(index, idx);
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box></Grid>




                        </Grid>
                      </Box>
                    ))}
                    <Box sx={{ width: "100%" }}>
                      <Divider sx={{ marginBottom: 2 }} />
                      <TextField
                        fullWidth
                        placeholder="Enter your special request here. We'll do our best to accommodate"
                        variant="standard"
                        size="small"
                        sx={{
                          "& .MuiInputBase-root": {
                            border: "none",
                            "&::before": {
                              borderBottom: "none", // Remove the underline
                            },
                            "&::after": {
                              borderBottom: "none", // Remove the underline
                            },
                            "&:hover::before": {
                              borderBottom: "none", // Remove the underline on hover
                            },
                            "&.Mui-focused::before": {
                              borderBottom: "none", // Remove the underline when focused
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "black", // Customize label color
                          },
                          mb: 2, // Margin bottom to space out from Divider
                        }}
                        value={selectedRange.reservation[index].comment}
                        onChange={(e) => {
                          handleAddComment(e, index);
                        }}
                      />
                      <Divider />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}></Grid>
      <Grid item xs={12} sm={4} >
        <TotalCard
          totalAmount={selectedRange?.totalAmount}
          resetSelectedRange={resetSelectedRange}
          stylesThree={stylesThree}
        />
      </Grid>
    </Grid>
  );
};

export default InvoiceStep;
