import { CircularProgress, IconButton, Link, TextField, Typography } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";

const ForgetPasswordForm = ({

  setPageType,

  main_widget_color,
  handleChange,
  stylesThree
}) => {
  const auth = useAuth();
  const [email, setEmail] = useState("")
  const [err, setErr] = useState("")
  const [message, setMessage] = useState("")
  const handleSubmit = async () => {
    try {
      const { success, response, message } = await auth.resetPassword({ email });

      if (success) {
        setErr('')
        setMessage(response?.message)
        

      } else {


        setErr(message);
      }
    } catch (e) {

      setErr('An unexpected error occurred');
    }
  };





  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 500, mb: 3, color: "#333" }}>
        Forget Password
      </Typography>
      <TextField
        fullWidth
        label="Email"
        variant="standard"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Typography variant="body2" sx={{ fontWeight: 500, color: "red" }}>
        {err}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 500,  color: main_widget_color ? main_widget_color : 'var(--primary-color)', }}>
        {message}
      </Typography>
      <IconButton

        sx={{
          p: 0,
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          mb: 2,
          color: main_widget_color ? main_widget_color : 'var(--primary-color)',
          ...stylesThree
        }}
        onClick={handleSubmit}
      >
        {auth?.loadingReset? <CircularProgress size={20} /> :
        
        <ArrowCircleRightIcon sx={{ fontSize: 60 }} />}
      </IconButton>
      <Typography variant="body2"
        onClick={() => setPageType("signIn")}
        sx={{
          fontWeight: 400, fontSize: "14px",
          textDecoration: "underline", color: main_widget_color ? main_widget_color : 'var(--primary-color)',
          cursor: "pointer"
        }}>
        Login
      </Typography>
    </>
  );
};

export default ForgetPasswordForm;
