import { useState } from "react";
import { Box, Table, TableRow, Typography, styled } from "@mui/material";
import { convertTo24HourFormat } from "../Pages/core/_utils";
import { BorderBottom, BorderLeft, BorderRight } from "@mui/icons-material";

interface SlotCellProps {
  selected?: boolean;
  disabled?: boolean;
  main_widget_color: string;
  hoverEffect: boolean;
  hoveredIndex:any
 index:any
 borderRight:any
 borderTop:any
 borderBottom:any
}

const TimeCell = styled("td")(() => ({
  padding: 5,
  textAlign: "center",
  width: "80px",
  backgroundColor: "#fff",
  border: "none",
}));

const SlotCell = styled("td")<SlotCellProps>(({ selected, disabled, main_widget_color, hoverEffect,hoveredIndex ,borderRight,borderTop,borderBottom}) => ({
  padding: "7px",
  textAlign: "center",
  width: "80px",
  backgroundColor: selected ? (main_widget_color !== null ? `${main_widget_color} !important ` : "#e0e0e0") : "#fff",
  cursor: disabled ? "not-allowed" : "pointer",
  color: disabled ? "#9e9e9e" : "black",
  // transition: "border 0.3s ease", // Smooth transition
  
  borderLeft:borderRight ? `1px solid ${main_widget_color} !important` : "none",
  borderRight:borderRight ? `1px solid ${main_widget_color} !important` : "none",
  borderTop:borderTop ? `1px solid ${main_widget_color} !important` :!selected? "1px solid #e2e2e2":'',
  borderBottom: borderBottom ? `1px solid ${main_widget_color} !important` : "",
  // "&:hover": {
  //   BorderBottom: hoverEffect ? `1px solid ${main_widget_color}` : "none",
  // },
}));

const TimePicker = ({
  availableTimeData,
  currentRange,
  setCurrentRange,
  timeRanges,
  isReserved,
  selectedHourRate,
  availability,
  main_widget_color,
  css_widget
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleTimeClick = (time) => {
    if (!isDisabled(time)) {
      setCurrentRange({ start: time, end: null });

      const startTimeIndex = availableTimeData.findIndex((t) => t.time === time);
      if (startTimeIndex === -1) return;

      const stepsToGo = selectedHourRate.rate_per / 0.5;
      const endTimeIndex = startTimeIndex + stepsToGo;

      if (endTimeIndex < availableTimeData.length) {
        const endTime = availableTimeData[endTimeIndex].time;
        const convertedEndTime = convertTo24HourFormat(endTime);
        const isTimeUnavailable = timeRanges.some((range) => convertedEndTime >= convertTo24HourFormat(range.start));
        const isEndTimeAvailable = availability.some((slot) => slot.start === endTime && slot.available);

        if (!isTimeUnavailable || isEndTimeAvailable) {
          setCurrentRange((prevRange) => ({
            ...prevRange,
            end: endTime
          }));
        }
      }
    }
  };

  const isSelected = (time: string) => {
    if (!currentRange.start) return false;
    const startIndex = availableTimeData.findIndex((t: any) => t.time === currentRange.start);
    const endIndex = currentRange.end
      ? availableTimeData.findIndex((t: any) => t.time === currentRange.end)
      : startIndex;
    const currentIndex = availableTimeData.findIndex((t: any) => t.time === time);

    return currentIndex >= startIndex && currentIndex <= endIndex;
  };

  const isDisabled = (time: string) => {
    return timeRanges.some((range) => {
      const startIndex = availableTimeData.findIndex((t: any) => t.time === range.start);
      const endIndex = availableTimeData.findIndex((t: any) => t.time === range.end);
      const currentIndex = availableTimeData.findIndex((t: any) => t.time === time);

      return currentIndex >= startIndex && currentIndex <= endIndex;
    });
  };

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
console.log('availableTimeData',availableTimeData)
  return (
    <Table>
      <tbody>
        {availableTimeData.map((time: any, index: number) => (
          <TableRow key={index}>
            <TimeCell>
              <Typography variant="body2">{time.time}</Typography>
            </TimeCell>
            <SlotCell
              colSpan={5}
              onClick={() => handleTimeClick(time.time)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              selected={isSelected(time.time) || isDisabled(time.time) || isReserved(time.time)}
              disabled={isDisabled(time.time) || isReserved(time.time)}
              main_widget_color={main_widget_color}
              hoverEffect={hoveredIndex === index || hoveredIndex === index - 1 || hoveredIndex === index - 2}
              hoveredIndex={hoveredIndex}
              index={index}
              borderRight={hoveredIndex === (index - 1) || hoveredIndex === index || hoveredIndex === (index - 2)  ? true:false}
              borderTop={hoveredIndex === (index ) ? true:false}
              borderBottom={ hoveredIndex === (index -2)? true:false}

            />
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default TimePicker;
