import { format, parseISO } from "date-fns";

const FilterFromUntilTimeData = (
  timeData: any,
  from: string,
  until: string,
) => {
  let startCapture = false;

  const filteredData = timeData.filter((timeEntry: any) => {
    if (timeEntry.time === from) {
      startCapture = true;
    }
    if (startCapture) {
      if (timeEntry.time === until) {
        startCapture = false;

        return true;
      }

      return true;
    }

    return false;
  });

  return filteredData;
};
const GetCurrentDay = () => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[new Date().getDay()];

  return currentDay;
};
const GetHoursRate = (ordersForm,dayOfWeek) => {
  // const currentDay = GetCurrentDay()
  const currentDay = dayOfWeek;

  const hourlyRate = ordersForm.find((rate) => rate.day === currentDay);

  return hourlyRate;
};
const getDayOfWeek = (dateString: string): string => {
  const date = parseISO(dateString);
  return format(date, "EEEE");
};
const calculateTimeDifference = (startTime: string, endTime: string) => {
  const start = convertTo24HourFormat(startTime);
  const end = convertTo24HourFormat(endTime);

  const startInMinutes = start.hours * 60 + start.minutes;
  const endInMinutes = end.hours * 60 + end.minutes;

  const diffInMinutes = endInMinutes - startInMinutes;
  const diffHours = Math.floor(diffInMinutes / 60);
  const diffMinutes = diffInMinutes % 60;

  return { hours: diffHours, minutes: diffMinutes };
};
const convertTo24HourFormat = (
  time: string,
): { hours: number; minutes: number } => {
  const timeRegex = /(\d{1,2}):(\d{2})\s?(AM|PM)/i;

  const match = time.match(timeRegex);
  if (!match) {
    throw new Error("Invalid time format");
  }

  const [, hoursStr, minutesStr, period] = match;
  let hours = parseInt(hoursStr, 10);

  if (period.toUpperCase() === "PM" && hours !== 12) {
    hours += 12;
  } else if (period.toUpperCase() === "AM" && hours === 12) {
    hours = 0;
  }

  return { hours, minutes: parseInt(minutesStr, 10) };
};
const formatDateString = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};
const generateOptions = (max) => {
  return Array.from({ length: max + 1 }, (_, i) => i);
};

const formatDateToYYYYMMDD = (date) => {
  // Ensure the input is a Date object
  if (!(date instanceof Date)) {
    throw new Error("Input must be a Date object");
  }

  // Extract the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Format the date string
  return `${year}-${month}-${day}`;
};

const hourUnitFromatter = (reservation)=>{
 
  if(reservation === 1) return 'Hour'
  return 'Hours'


}
const extractStyles = (cssString, className) => {
  const regex = new RegExp(`${className}\\s*{([^}]*)}`, 'm');
  const match = cssString.match(regex);
  if (match && match[1]) {
      const stylesArray = match[1].trim().split(';').filter(Boolean);
      const stylesObject = stylesArray.reduce((acc, style) => {
          const [property, value] = style.split(':').map(item => item.trim());
          if (property && value) {
              acc[property] = value;
          }
          return acc;
      }, {});
      return stylesObject;
  }
  return {};
};
export {
  FilterFromUntilTimeData,
  GetHoursRate,
  GetCurrentDay,
  calculateTimeDifference,
  convertTo24HourFormat,
  formatDateString,
  generateOptions,
  getDayOfWeek,
  formatDateToYYYYMMDD,
  hourUnitFromatter,
  extractStyles
};
