import { Box, IconButton, Typography } from "@mui/material";
import {format} from 'date-fns'
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { extractStyles } from "../Pages/core/_utils";

const DayCard = ({day,onResetDay,css_widget})=>{



  const [hfContainerStyles, setHfContainerStyles] = useState({});
  const [dateWrapStyles, setdateWrapStyles] = useState({});

  useEffect(() => {
      if (css_widget && typeof css_widget === 'string') {
          const extractedStyles = extractStyles(css_widget, '.card-wrap');
          const extractedStylesTwo = extractStyles(css_widget, ' .date-wrap');
          setHfContainerStyles(extractedStyles);
          setdateWrapStyles(extractedStylesTwo)
      } else {
          console.error('css_widget is undefined or not a string');
      }
  }, [css_widget]);
    return(
        <Box
        mb={2}
        px={2}
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ...hfContainerStyles,
          ...dateWrapStyles
        }}
      
      >
        <Typography>
          {day ? format(day, "EEEE, MMM dd, yyyy") : "No date selected"}
        </Typography>
        <IconButton
          onClick={() => {
            onResetDay(day);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    )
}

export default DayCard