import {
  IconButton,
  TextField,
  Typography,
  Box,
  Icon,
  Button,
} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useAuth } from "../../hooks/useAuth";

const SignInForm = ({
  signInForm,
  handleChange,
  handleSubmit,
  userConnected,
  formErrors,
  main_widget_color,
  stylesThree, setPageType,
}) => {

  const auth = useAuth();
  function renderArrayMultiline(arr = []) {
    if (!arr || !Array.isArray(arr)) {
      return;
    }

    return arr?.map((e) => {
      return (
        <>
          {e}
          <br />
        </>
      );
    });
  }
  const goToForgetPassword = () => {
    setPageType("forrgetPassword");
  };
  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 500, color: "#333" }}>
          {auth.isLoggedIn ? "Continue as" : "Log In"}
        </Typography>
        {auth.isLoggedIn && (
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, color: "#333" }}
            textAlign={"center"}
          >
            {auth.user.full_name}
          </Typography>
        )}
      </Box>
      {!auth.isLoggedIn && (
        <>
          <TextField
            fullWidth
            label="Email"
            variant="standard"
            value={signInForm.email}
            onChange={(e) => handleChange("email", e)}
          />
          <TextField
            fullWidth
            type="password"
            label="Password"
            variant="standard"
            onChange={(e) => handleChange("password", e)}
            value={signInForm.password}
          />
        </>
      )}
      <Typography variant="body2" sx={{ fontWeight: 500, color: "red" }}>
        {formErrors.message}
      </Typography>
      <IconButton

        sx={{
          p: 0,
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          color: main_widget_color ? main_widget_color : 'var(--primary-color)',
          ...stylesThree
        }}
        onClick={handleSubmit}
      >
        {/* <Icon icon={"mdi:arrow-right-bold"} /> */}
        <ArrowCircleRightIcon sx={{ fontSize: 60 }} />
      </IconButton>
      {!auth.isLoggedIn && <Box sx={{ mt: 3 }}>
        <Typography variant="body2"
          onClick={goToForgetPassword}
          sx={{
            fontWeight: 400, fontSize: "14px",
            textDecoration: "underline", color: main_widget_color ? main_widget_color : 'var(--primary-color)',
            cursor: "pointer"
          }}>
          Forgot My Password
        </Typography>
      </Box>}
    </>
  );
};

export default SignInForm;
