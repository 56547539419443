import { Grid, Typography, Box, Button, MobileStepper, useTheme, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useAuth } from "../hooks/useAuth";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { extractStyles } from "../Pages/core/_utils";

const CustomBottomStepper = ({
    steps,
    activeStep,
    handleNext,
    handleBack,
    selectedRange,
    second_widget_color,
    main_widget_color,
    css_widget
}) => {
    const theme = useTheme();
    const auth = useAuth();
    const isSelectedRangeEmpty =
        !selectedRange ||
        !selectedRange.reservation ||
        selectedRange.reservation.length === 0;

    // Check if the screen is small
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [hfContainerStyles, setHfContainerStyles] = useState({});

    useEffect(() => {
        if (css_widget && typeof css_widget === 'string') {
            const extractedStyles = extractStyles(css_widget, '.hf-container');
            setHfContainerStyles(extractedStyles);
        } else {
            console.error('css_widget is undefined or not a string');
        }
    }, [css_widget]);
    
    return (
        <Box sx={{
            width: "100%",
            position: "fixed",
            display: "flex",
            alignItems: "center",
            backgroundColor: main_widget_color ? `${main_widget_color} !important` : 'var(--primary-color)',
            justifyContent: "space-between",
            py: "14px",
            bottom: 0,
            ...hfContainerStyles,
        }}
            className='footer-container-custom hf-container'
        >
            {activeStep !== 0 ? (
                <Button size="small" sx={{ color: 'white' }} disabled={activeStep === 0} onClick={handleBack}>
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                    Back
                </Button>
            ) : <div></div>}

            {!isSmallScreen && (
                <Box sx={{ width: "450px", height: "15px", borderRadius: "12px", border: "2px solid white" }} >
                    <Grid container>
                        {steps?.map((step, key) =>
                            <Grid item xs={3} key={key} sx={{
                                backgroundColor: key < activeStep || key === 0 ? "transparent" : "white",
                                height: "12px",
                                borderBottomRightRadius: key === steps.length - 1 ? "6px" : "0px",
                                borderTopRightRadius: key === steps.length - 1 ? "6px" : "0px",
                                borderBottomLeftRadius: key === 0 ? "6px" : "0px",
                                borderTopLeftRadius: key === 0 ? "6px" : "0px",
                            }}
                                textAlign={"center"} >
                                <Typography fontSize={9} variant='body1' sx={{ color: key < activeStep || key === 0 ? "white" : "#5C5C5C" }} >{step}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}

            {(!isSelectedRangeEmpty && activeStep !== steps.length - 1 && activeStep !== 4) ? (
                <Button
                    sx={{ color: 'white' }}
                    size="small"
                    onClick={handleNext}
                >
                    Next
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </Button>
            ) : <div></div>}
        </Box>
    )
}

export default CustomBottomStepper;
